<template>
  <div>
    <div v-if="helper.show">
      <el-card
        class="box-card"
        shadow="hover"
        style="margin: 0 auto; margin-bottom: 20px"
      >
        <el-form>
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="viewItems.pickedComps"
            v-for="(name, index) in $options.componentsNames"
            :key="index"
          >
            <el-form-item>
              <el-checkbox
                :label="name"
                :disabled="frobidFunc(name)"
                :checked="checkFunc(name)"
              ></el-checkbox>
            </el-form-item>
          </el-checkbox-group>

          <el-form-item>
            <el-button type="primary" @click="chooseComps">保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <!-- 对选择的组件进行配置 -->
      <div v-if="this.helper.compConfigsShow">
        <component-form
          v-for="oriComponentOpts in pickedCompsDes"
          :key="oriComponentOpts['name']"
          :compItems="oriComponentOpts"
          @validationResCome="getValidationRes"
          @componentConfigComing="saveComponentConfig"
          style="margin: 0 auto; margin-bottom: 20px"
        ></component-form>
      </div>
      <div id="zhanwei"></div>
      <PrevNext
        @prev-click="onPrevClick"
        @next-click="onNextClick('BuildingStatus')"
      ></PrevNext>
    </div>

    <div v-else>
      <el-empty description="正在获取数据"></el-empty>
    </div>
  </div>
</template>

<script>
import ComponentForm from "../AppConfig/AppCustomConfigForm.vue";
import axios from "axios";
import PrevNext from "../../Common/PrevNext.vue";
import { PROCESS_ACTIVE } from "../../../assets/constant.js";

export default {
  data() {
    return {
      saved: false,
      checkAll: false,
      viewItems: {
        pickedComps: [], // 用来存储用户选择的组件的名称
      }, // 视图绑定的数据
      helper: {
        show: false,
        compConfigsShow: false,
        validationObj: {},
      },
      pickedCompsDes: [], // 用户选择的组件的描述信息(是否必要, 是否隐藏等), 要传递给子组件
      reqData: {}, // 组件页面最终提交到大JSON中的数据
    };
  },

  components: {
    ComponentForm,
    PrevNext,
  },

  created() {
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.COMPCONFIG);
    axios
      .get("/api/driver/getYamlFeildJson") // 接口地址填写
      // axios({
      //   method: "get",
      //   url: "http://127.0.0.1:4523/mock/622177/api/driver/getYamlFeildJson",
      // })
      .then((response) => {
        console.log(response);
        this.helper.show = true;
        this.$options.oriComponentsOpts = response.data.data;
        if (this.$store.getters.reqData.cloud === "ali") {
          //阿里云不需要已在集群创建阶段自动安装了ingress-nginx
          this.$options.oriComponentsOpts =
            this.$options.oriComponentsOpts.filter(
              (oriComponentsOpt) => oriComponentsOpt.name !== "ingress-nginx"
            );
        }
        this.$options.componentsNames = this.getComponentsNames();

        const componentsFields = this.$store.getters.reqData["appCustomConfig"];
        if (Object.keys(componentsFields).length > 0) {
          this.viewItems.pickedComps = Object.keys(componentsFields);
          this.helper.compConfigsShow = true;
          this.modifyDefaultVal(
            this.$options.oriComponentsOpts,
            componentsFields
          );
          this.getPickedComps();
        }
      })
      .catch(function (error) {
        if (error.response) {
          // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
        } else if (error.request) {
          // 请求已经成功发起，但没有收到响应
        } else {
          // 发送请求时出了点问题
        }
      });
  },
  watch: {
    //勾选修改后未点保存的处理
    "viewItems.pickedComps": {
      handler(val) {
        this.saved = false;
      },
      immediate: true,
    },
  },
  mounted() {
    console.log(this.$options.componentsNames);
  },

  oriComponentsOpts: [
    // {
    //   name: "milvs",
    //   config: {
    //     "milvs.str1": {
    //       field_type: "string",
    //       field_default: "default1",
    //       is_required: true,
    //       is_flat: true,
    //     },
    //     "milvs.boolean1": {
    //       field_type: "boolean",
    //       field_default: true,
    //       is_required: true,
    //       is_flat: false,
    //     },
    //     "milvs.int1": {
    //       field_type: "int",
    //       field_default: 23,
    //       is_required: true,
    //       is_flat: true,
    //     },
    //   },
    // },
    // {
    //   name: "nps",
    //   config: {
    //     "nps.str1": {
    //       field_type: "string",
    //       field_default: "default1",
    //       is_required: true,
    //       is_flat: true,
    //     },
    //     "nps.int1": {
    //       field_type: "int",
    //       field_default: 3223,
    //       is_required: true,
    //       is_flat: false,
    //     },
    //     "nps.boolean1": {
    //       field_type: "boolean",
    //       field_default: false,
    //       is_required: true,
    //       is_flat: true,
    //     },
    //   },
    // },
  ],

  methods: {
    frobidFunc(name) {
      if (
        name == "cert-manager" ||
        name == "ingress-nginx" ||
        name == "kubeflow" ||
        name == "prometheus-stack" ||
        name == "dmetasoul-ide" ||
        name == "kubernetes-replicator"
      ) {
        return true;
      }
      return false;
    },
    checkFunc(name) {
      if (
        name == "cert-manager" ||
        name == "ingress-nginx" ||
        name == "kubeflow" ||
        name == "prometheus-stack" ||
        name == "dmetasoul-ide" ||
        name == "kubernetes-replicator"
      ) {
        return true;
      }
      return false;
    },
    saveComponentConfig(name, fieldsItems) {
      this.reqData[name] = fieldsItems;
    },

    clearComponentConfig() {
      const reqDataKeys = Object.keys(this.reqData);

      for (let key of reqDataKeys) {
        if (this.viewItems.pickedComps.indexOf(key) === -1) {
          delete this.reqData[key];
        }
      }
    },

    modifyDefaultVal(oriComponentOpts, componentsFields) {
      for (let [compKey, fields] of Object.entries(componentsFields)) {
        // 一个组件
        const ind = this.getIndexFromOri(compKey);
        const compOpts = oriComponentOpts[ind];
        for (let [key, val] of Object.entries(fields)) {
          compOpts["config"][key]["field_default"] = val;
        }
      }
    },

    getIndexFromOri(name) {
      for (let i = 0; i < this.$options.oriComponentsOpts.length; i++) {
        if (this.$options.oriComponentsOpts[i].name === name) {
          return i;
        }
      }
      return -1;
    },

    getComponentsNames() {
      const names = [];
      this.$options.oriComponentsOpts.forEach((comp) => {
        names.push(comp.name);
      });
      console.log(names);
      return names;
    },

    getValidationRes(name, validRes) {
      this.helper.validationObj[name] = validRes;
    },

    areAllPassed() {
      for (let [k, v] of Object.entries(this.helper.validationObj)) {
        if (!v) {
          return false;
        }
      }
      return true;
    },

    chooseComps() {
      // this.clearComponentConfig();
      this.saved = true;
      const checkedCount = this.viewItems.pickedComps.length;
      this.checkAll = checkedCount === this.$options.componentsNames;
      this.getPickedComps();
      this.deleteValid();
      this.helper.compConfigsShow = true;
      console.log(this.isIndeterminate + "1");
    },
    handleCheckAllChange(val) {
      console.log(this.viewItems.pickedComps);
      this.viewItems.pickedComps = val
        ? this.$options.componentsNames
        : ["cert-manager", "ingress-nginx"];
    },
    deleteValid() {
      const valiKeys = Object.keys(this.helper.validationObj);
      valiKeys.forEach((k) => {
        if (this.viewItems.pickedComps.indexOf(k) === -1) {
          delete this.helper.validationObj[k];
        }
      });
    },

    updateCompsOpts() {
      // 查看reqData中已经存储过的组件的字段配置, 说明之前用户已经配置过
      const reqDataKeys = Object.keys(this.reqData);

      for (let name of reqDataKeys) {
        // name: milvs....
        const ind = this.getIndexFromOri(name);
        const oriConfigs = this.$options.oriComponentsOpts[ind]["config"]; // "milvs.a1: {field_type: "string", field_default: "default1"}"
        const reqFields = this.reqData[name]; // {milvs.a1: xxxx, milvs.s2: xxxx}

        for (let [configKey, configObj] of Object.entries(oriConfigs)) {
          configObj["field_default"] = reqFields[configKey];
        }
      }
    },

    getPickedComps() {
      const names = this.viewItems.pickedComps;

      // 说明用户进行了二次选择, 要首先置空
      if (this.pickedCompsDes.length > 0) {
        this.pickedCompsDes = [];
      }

      this.updateCompsOpts();
      this.$options.oriComponentsOpts.forEach((comp) => {
        if (names.indexOf(comp["name"]) !== -1) {
          this.pickedCompsDes.push(comp);
        }
      });
    },

    getPickedInd(comp) {
      for (let i = 0; i < this.pickedCompsDes.length; i++) {
        if (this.pickedCompsDes[i]["name"] === comp["name"]) {
          return i;
        }
      }
      return -1;
    },

    onPrevClick() {
      this.$router.push("/form/appCommonConfig");
    },

    onNextClick(toName) {
      if (!this.saved) {
        this.$message({
          message: "保存未修改,请先点击保存",
          type: "warning",
        });
        return false;
      }
      const res = this.getFinalReq();
      if (!res) {
        return false;
      }
      //huawei云字段特殊处理
      if (this.$store.getters.reqData.cloud === "huawei") {
        this.$store.getters.reqData.driverOptions.cloud = "huawei"
      }
      //ali云字段特殊处理
      if (this.$store.getters.reqData.cloud === "ali") {
        if (
          !Array.isArray(
            this.$store.getters.reqData.driverOptions.workerInstanceTypes
          )
        ) {
          this.$store.getters.reqData.driverOptions.workerInstanceTypes = [
            this.$store.getters.reqData.driverOptions.workerInstanceTypes,
          ];
        }
        for (let nodesOption of this.$store.getters.reqData.nodesOptions) {
          delete nodesOption.autoScalingTypeCopy;
          delete nodesOption.spotPriceLimitCopy;
          if (nodesOption.scalingGroupSpotStrategy.length)
            nodesOption.scalingGroupSpotStrategy =
              nodesOption.scalingGroupSpotStrategy[0];
          if (!nodesOption.loginKeyPair) nodesOption.loginKeyPair = "";
          else if (!nodesOption.loginPassword) nodesOption.loginPassword = "";
          if(Array.isArray(nodesOption.systemDiskCategory)){
            nodesOption.systemDiskCategory = nodesOption.systemDiskCategory[0]
          }
        }
      }
      //由于SSO_CONFIG为非String类型，此处为了保护后端requestParam中appCommonConfig<String,String>结构，将结果放入AppCommonConfigToObj，由后端去转回appCommonConfig
      this.$store.dispatch("setAppCommonConfigToObj");
      this.$store.dispatch("setUserIdIntoReq");
      let reqData = this.$store.getters.reqData;
      console.log(JSON.stringify(reqData));

      axios({
        method: "post",
        url: "/api/driver/create",
        data: reqData,
        validateStatus: function (status) {
          return status === 200; // Resolve if the status code is not equal to 200
        },
      })
        .then((res) => {
          console.log(res);
          this.$store.commit("setClusterPrimaryKey", res.data.data);
          // this.$store.commit('setClusterId', 'ba66457c-7bf2-11ec-88a3-0255ac101f6b')

          const status = res.data.code;

          if (status === 200) {
            console.log(res.data);
            console.log("节点配置完成，开始创建，跳转至进度页面...");
            this.$store.commit("clearReqData");
            // this.$router.push(to);
            this.$router.push({
              name: "BuildingStatus",
              params: {
                clusterPrimaryKey: this.$store.getters.clusterPrimaryKey,
              },
            });
          } else {
            this.$store.commit("setObjToAppCommonConfig");
            this.$alert(res.data.message, "提示:", {
              confirmButtonText: "确定",
              callback: (action) => {
                // 用户点击确认之后的逻辑可以卸载这里
              },
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
          } else if (error.request) {
            // 请求已经成功发起，但没有收到响应
          } else {
            // 发送请求时出了点问题
          }
        });
    },

    getFinalReq() {
      console.log(JSON.stringify(this.helper.validationObj));
      this.clearComponentConfig();
      // // 用户没有配置组件
      // if (Object.keys(this.helper.validationObj).length === 0) {
      //   return true;  // 没有填就直接通过验证就行了
      // }

      if (!this.areAllPassed()) {
        this.$message({
          message: "请检查填写的表单",
          type: "warning",
        });
        return false;
      } else {
        this.$store.commit("setAppCustomConfig", this.reqData);
        console.log("reqData", JSON.stringify(this.reqData));
        console.log("通过");
        console.log(JSON.stringify(this.$store.getters.reqData));
        return true;
      }
    },
  },
};
</script>

<style scoped>
#zhanwei {
  height: 50px;
}
</style>