<template>
  <el-card class="box-card" shadow="hover">
    <h2>{{ nonReactiveData["name"] }}</h2>
    <el-divider></el-divider>
    <el-form :model="viewItems" ref="compForm" @mouseleave="validateAndSave()">
      <el-form-item
        v-for="(desc, field) in nonReactiveData['showFields']"
        :key="field"
        :label="field"
        :prop="field"
        :rules="desc['rules']"
      >
        <el-checkbox
          v-if="desc['field_type'] === 'boolean'"
          v-model="viewItems[field]"
          >{{ viewItems[field] }}</el-checkbox
        >

        <el-input
          v-if="desc['field_type'] === 'string'"
          v-model="viewItems[field]"
        ></el-input>
        <el-input
          v-if="desc['field_type'] === 'int'"
          v-model.number="viewItems[field]"
        ></el-input>
      </el-form-item>

      <el-switch v-model="helpers.advancedOptsShow" inactive-text="高级选项" />
      <br />
      
        <!-- 隐藏的节点 -->
        <el-form
          v-if="helpers.advancedOptsShow"
          :model="viewItems"
          ref="compHiddenForm"
        ><el-scrollbar height="300px" always>
          <el-form-item
            v-for="(desc, field) in nonReactiveData['hiddenFields']"
            :key="field"
            :label="field"
            :prop="field"
            :rules="desc['rules']"
          >
            <el-checkbox
              v-if="desc['field_type'] === 'boolean'"
              v-model="viewItems[field]"
              >{{ viewItems[field] }}</el-checkbox
            >
            <el-input
              v-if="desc['field_type'] === 'string'"
              v-model="viewItems[field]"
            ></el-input>
            <el-input
              v-if="desc['field_type'] === 'int'"
              v-model.number="viewItems[field]"
            ></el-input>
          </el-form-item>
          </el-scrollbar>
        </el-form>
      
    </el-form>
  </el-card>
</template>

<script>
export default {
  props: ["compItems"],
  data() {
    return {
      viewItems: {}, // 表单双向绑定的视图数据
      helpers: {
        advancedOptsShow: false, // 高级选项字段的展示与否
      },
      nonReactiveData: {}, 
      // 之后可以改进, 因为只要在data中的, vue3都会使用代理对象对其进行封装, 但由于对性能的影响不大, 目前可以放在data中
      // 该场景下也不可以存入this.$options 因为$options是单例的，不适用于多Vue实例化对象情景
    };
  },
  methods: {
    getFormObj() {
      // 以后可以在这进行从后端获取数据以及formObj的映射
      const configs = this.nonReactiveData["configs"];
      return this.attachRules(this.removeNoDisplay(configs));
    },

    removeNoDisplay(configs) {
      const obj = {};
      for (let [field, desc] of Object.entries(configs)) {
        if (desc["field_type"] === "default") {
          continue;
        }
        obj[field] = desc;
      }
      return obj;
    },

    attachRules(configs) {
      const obj = {};

      for (let [field, desc] of Object.entries(configs)) {
        const compoundDesc = JSON.parse(JSON.stringify(desc));
        compoundDesc["rules"] = [];
        if (desc["is_required"]) {
          compoundDesc["rules"].push({
            required: true,
            message: field + "是必填的",
          });
        }
        if (desc["field_type"] === "int") {
          compoundDesc["rules"].push({
            type: "number",
            message: field + "必须为数字值",
          });
        }
        obj[field] = compoundDesc;
      }

      // console.log(obj);
      return obj;
    },

    attachDefaultFields() {
      const attachedFields = JSON.parse(JSON.stringify(this.viewItems));
      const configs = this.nonReactiveData["configs"];
      for (let [field, desc] of Object.entries(configs)) {
        if (desc["field_type"] === "default") {
          attachedFields[field] = desc["field_default"];
        }
      }
      return attachedFields;
    },

    validateAndSave() {
      const p1 = this.$refs["compForm"].validate();
      const p2 = this.$refs["compHiddenForm"]
        ? this.$refs["compHiddenForm"].validate()
        : Promise.resolve(true);

      Promise.all([p1, p2])
        .then((arr) => {
          const validateRes = arr.every((item) => item);
          this.$emit(
            "validationResCome",
            this.nonReactiveData["name"],
            validateRes
          );
          if (validateRes) {
            this.$emit(
              "componentConfigComing",
              this.nonReactiveData["name"],
              this.attachDefaultFields()
            );
          }
        })
        .catch((err) => {
          this.$emit("validationResCome", this.nonReactiveData["name"], false);
        });
    },
    getShowAndHiddenFields() {
      const formObj = this.nonReactiveData["formObj"];
      const showFields = {};
      const hiddenFields = {};

      for (let [field, desc] of Object.entries(formObj)) {
        if (desc["is_flat"]) {
          showFields[field] = desc;
        } else {
          hiddenFields[field] = desc;
        }
      }

      return {
        showFields: showFields,
        hiddenFields: hiddenFields,
      };
    },

    getViewItems() {
      const formObj = this.nonReactiveData["formObj"];
      for (let [field, desc] of Object.entries(formObj)) {
        this.viewItems[field] = desc["field_default"];
      }
    },
  },

  created() {
    // 在created中最好初始化工作
    this.nonReactiveData["name"] = this.compItems.name;
    this.nonReactiveData["configs"] = this.compItems.config;
    this.nonReactiveData["formObj"] = this.getFormObj();
    const { showFields, hiddenFields } = this.getShowAndHiddenFields();
    this.nonReactiveData["showFields"] = showFields;
    this.nonReactiveData["hiddenFields"] = hiddenFields;
    this.getViewItems();
  },

  watch: {
    "helpers.advancedOptsShow"(val) {
      if (val === false) {
        const p = this.$refs["compHiddenForm"].validate();
        p.then(() => {}).catch(() => {
          this.helpers.advancedOptsShow = true;
        });
      }
    },
  },

  mounted() {
    // console.log(this.nonReactiveData);
    this.validateAndSave();
  },
};
</script>

<style lang="less">
.switch {
  margin-bottom: 0.1rem;
}
</style>